<template>
  <div class="brdr-bottom-1 brdr-cl-matterhorn py25" :class="{ 'brdr-top-1' : !alternative }">
    <h3 v-show="!getCurrentUpsellProduct.bundle_options && !bookPublicationForm" class="secondary-font mt10 weight-500">
      {{ getCurrentUpsellProduct.name }}
    </h3>
    <p v-show="!bookPublicationForm" class="fs-medium-small cl-black weight-900">
      {{ getCurrentUpsellProduct.product_type_name ? getCurrentProduct.product_type_name : $t('Subscription') }}
    </p>
    <p v-show="discountPercent && !bookPublicationForm" class="badge cl-burnt-sienna h5 w-100 weight-600 mt20">
      {{ $t('By ordering a subscription, you save up to ') }} {{ discountPercent }}% {{ $t(' compared to the price of the cover!') }}
    </p>
    <form class="custom-options" data-testid="bundle-options">
      <div v-for="option in getCurrentUpsellProduct.bundle_options" :key="('bundleOption_' + option.option_id)">
        <div class="custom-option prenumerate mb20">
          <div class="row mt20 select-wrapper relative">
            <span class="col-xs-12 col-md-6 flex middle-xs h5">{{ option.title }}</span>
            <div class="col-xs-12 col-md-6">
              <select
                class="select"
                @change="setBundleOption(option, $event.target.value)"
              >
                <option :disabled="option.required ? true : false" selected value>
                  <template v-if="option.required">
                    Wybierz opcję
                  </template>
                  <template v-else>
                    (+0zł) Bez prezentu
                  </template>
                </option>
                <option
                  v-for="opval in option.product_links"
                  v-if="opval.product"
                  :key="opval.id"
                  :value="opval.id"
                >
                  {{ beautifyPrice(opval) }} {{ opval.product.name }}
                </option>
              </select>
            </div>
          </div>
          <subscription v-if="getCurrentSelectedOptionVariantSku[option.option_id]" :option-id="option.option_id" :product-option="getCurrentSelectedOptionVariantSku" :product="getCurrentUpsellProduct" />
        </div>
      </div>
    </form>
    <subscription v-if="!getCurrentUpsellProduct.bundle_options && getCurrentUpsellProduct.type_id === 'subscription'" :option-id="1" :product-option="getCurrentSelectedOptionVariantSku" :product="getCurrentUpsellProduct" />
    <subscription-add-to-cart :disabled="isSubscription.length > 0 ? (!IsAllPropsSelected || !isStartingNumberSelected) : !IsAllPropsSelected" :book-publication-form="bookPublicationForm" :product="getCurrentUpsellProduct" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import { SearchQuery } from 'storefront-query-builder'
import Vue from 'vue'
import { getBundleOptionsValues, getSelectedBundleOptions } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import isEqual from 'lodash-es/isEqual'
import SubscriptionAddToCart from 'theme/components/core/SubscriptionAddToCart'
import Subscription from 'theme/components/core/Subscription'
import config from 'config'

export default {
  name: 'ProductSwitch',
  props: {
    alternative: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Subscription,
    SubscriptionAddToCart
  },
  data () {
    return {
      selected: {
        '429': '',
        '431': ''
      },
      minMonthPrice: null,
      coverPrice: null,
      lastCalledSkus: []
    }
  },
  beforeMount () {
    this.getCoverPrice()
    this.getMinMonthPrice()
  },
  watch: {
    // todo: find a way to call only when all option will be selected in background
    getCurrentSubscriptionOptionSku: function (newVal, oldVal) {
      this.loadCurrentCustomOptions()
    }
  },
  methods: {
    getLabelFor (type) {
      if (this.listByCode && this.listByCode[type]) {
        let items = this.listByCode[type].options.filter(option => option.value === String(this.getCurrentUpsellProduct.publication_form))
        return (items && items.length) && items[0].label
      }
    },
    isOptionNotChangeable (option) {
      const { product_links, required } = option
      return product_links.length === 1 && required
    },
    loadCurrentCustomOptions () {
      let skus = Object.values(this.getCurrentSubscriptionOptionSku).map(option => option.sku)
      if (skus && skus.length) {
        this.$store.dispatch('subscription-product/load', { skus: skus })
      }
      this.lastCalledSkus = skus
    },
    getMinMonthPrice () {
      const currentOptions = this.getCurrentUpsellProduct.bundle_options
      if (!currentOptions) return
      const subOption = currentOptions.find(option => option.title.includes('prenum')) // get options with basic subscribtion product
      let minPrice = null
      for (let option of subOption.product_links) {
        let months = Number(option.sku.slice(-2))
        let currentMonthPrice = Number((option.price / months).toFixed(2))
        if (minPrice > currentMonthPrice || minPrice === null) minPrice = currentMonthPrice
      }
      if (minPrice) {
        this.minMonthPrice = minPrice // min subsciption price per month
      }
    },
    _prepareProductOption () {
      let product_option = {
        extension_attributes: {
          custom_options: [],
          configurable_item_options: [],
          bundle_options: []
        }
      }
      return product_option
    },
    beautifyPrice (product) {
      return product.price
        ? '(+' + product.price + ' zł)'
        : null
    },
    setBundleOption (option, id) {
      this.selected[option.option_id] = id
      const optionId = option.option_id; const optionQty = 1; const optionSelections = [parseInt(id)]
      const productOption = this._prepareProductOption()
      const attributes = {
        option_id: optionId,
        option_qty: optionQty,
        option_selections: optionSelections
      }

      // todo: make new store place for current bundle product
      const bundleOption = Object.assign({}, this.getCurrentUpsellProduct.product_option.extension_attributes.bundle_options, { [optionId]: attributes })
      const startingNumbers = this.getCurrentUpsellProduct.product_option.extension_attributes.starting_numbers
      productOption.extension_attributes.bundle_options = bundleOption
      if (startingNumbers) {
        productOption.extension_attributes['starting_numbers'] = startingNumbers
      }
      // todo: make new store place for current bundle product
      Vue.set(this.$store.state.product.related, 'upsell', [Object.assign({}, this.getCurrentUpsellProduct, { product_option: productOption })])
    },
    async getCoverPrice () {
      // get price of newwest cover product
      let searchQuery = new SearchQuery()

      searchQuery = searchQuery
        .applyFilter({ key: 'type_id', value: { 'eq': 'single_number' } })
        .applyFilter({ key: 'magazine_id', value: { 'eq': 46 } })
        .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
        .applyFilter({ key: 'status', value: { 'in': [1] } })
      // todo add include fields to minify item size
      const { items } = await this.$store.dispatch('product/findProducts', {
        query: searchQuery,
        size: 1,
        sort: 'id:desc',
        includeFields: config.entities.productRelated.includeFields,
        excludeFields: config.entities.productRelated.excludeFields,
        options: {
          populateRequestCacheTags: false,
          prefetchGroupProducts: false
        }
      })
      if (items && items.length) {
        this.coverPrice = items[0].price_incl_tax
      }
    }
  },
  computed: {
    ...mapGetters({
      getProductRelated: 'product/getProductRelated',
      getCurrentProduct: 'product/getCurrentProduct'
    }),
    selectedBundleOptions () {
      if (this.getCurrentUpsellProduct) {
        return getSelectedBundleOptions(this.getCurrentUpsellProduct, 'product_option.extension_attributes.bundle_options')
      }
      return {}
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    bookPublicationForm () {
      return this.getCurrentUpsellProduct.publication_form ? this.getLabelFor('publication_form') : null
    },
    divideText () {
      return this.bookPublicationForm
        ? 'inne formaty'
        : 'lub'
    },
    getCurrentSubscriptionOptionSku () {
      if (!this.getCurrentUpsellProduct.bundle_options) return { 1: { sku: this.getCurrentUpsellProduct.sku, product_id: this.getCurrentUpsellProduct.id } }

      let selectedSubscriptionOptions = {}
      const allBundeOptions = this.getCurrentUpsellProduct.bundle_options || []
      let bundleOptionsValues = getBundleOptionsValues(this.selectedBundleOptions, allBundeOptions)
      let subscriptionOption = bundleOptionsValues.filter(option => option.product && option.product.type_id === 'subscription')
      subscriptionOption.forEach(option => { selectedSubscriptionOptions[option.id] = { sku: option.sku, product_id: option.product.id } })
      return selectedSubscriptionOptions
    },
    getCurrentSelectedOptionVariantSku () {
      if (!this.getCurrentUpsellProduct.bundle_options) return { 1: { sku: this.getCurrentUpsellProduct.sku, product_id: this.getCurrentUpsellProduct.id } }

      let currentSelectedObject = {}
      this.getCurrentUpsellProduct.bundle_options.forEach(option => {
        let currentSelectedOption = this.selectedBundleOptions.find(opt => opt.option_id === option.option_id)
        let currentSelectedOptionVariantId = currentSelectedOption && currentSelectedOption.option_selections[0]
        currentSelectedObject[option.option_id] = this.getCurrentSubscriptionOptionSku[currentSelectedOptionVariantId]
      })
      return currentSelectedObject
    },
    isSubscription () {
      return this.getCurrentUpsellProduct && this.getCurrentUpsellProduct.bundle_options
        ? this.getCurrentUpsellProduct.bundle_options.map(x => this.getCurrentSelectedOptionVariantSku[x.option_id]).filter(x => x)
        : []
    },
    IsAllPropsSelected () {
      let bundleRequiredIds = this.getCurrentUpsellProduct.bundle_options && this.getCurrentUpsellProduct.bundle_options.filter(option => option.required).map(option => option.option_id.toString())
      let bundleSelectedIds = Object.keys(this.getCurrentUpsellProduct.product_option.extension_attributes.bundle_options)
      return bundleRequiredIds
        ? bundleRequiredIds.every(val => bundleSelectedIds.indexOf(val) >= 0)
        : true // for simple products
    },
    isStartingNumberSelected () {
      return this.getCurrentUpsellProduct.product_option.extension_attributes.starting_numbers
        ? this.isSubscription.length === this.getCurrentUpsellProduct.product_option.extension_attributes.starting_numbers.length
        : false
    },
    discountPercent () {
      return this.minMonthPrice && this.coverPrice
        ? (((this.coverPrice - this.minMonthPrice) / this.coverPrice) * 100).toFixed(0)
        : null
    },
    getCurrentUpsellProduct () {
      return this.getProductRelated['upsell']
        ? this.getProductRelated['upsell'][this.alternative ? 1 : 0]
        : null
    },
    productLinks () {
      return this.getCurrentProduct.product_links
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  @import '~theme/css/base/text';
  $color-main: color(main);

.select-wrapper {
  &::after {
    content: '';
    border: solid $color-main;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  select {
    font-family: "Work Sans";
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #F7F7F7;
    border: none;
    outline: 0;
    border-radius: 50px;
    width: 100%;
    appearance:none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:hover,
    &:focus {
      outline: none;
      border-color: $color-main;
    }

    &:disabled,
    &:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    option {
      font-size: 17px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #fff;
      outline: 0;
    }
  }
}
.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #1396EC;
  line-height: 0.1em;
  margin: 40px 0 20px;
}
</style>
