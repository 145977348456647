<template>
  <div class="products-carousel w-100">
    <no-ssr>
      <carousel
        v-bind="sliderConfig"
        @pageChange="setMuted"
        :infinite="true"
        ref="carouselHomepage"
      >
        <slide
          v-for="(product, index) in products"
          :key="index"
        >
          <product-tile
            class="collection-product"
            :product="product"
            :is-main-page="true"
            :link-active="false"
            :position="index"
            :list="list"
            @product-slide-click="handleSlideClick(index)"
            :omnibus-price="omnibusPriceMap[product.sku]"
          />
        </slide>
        <slide class="image-box" />
      </carousel>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { onlineHelper } from '@vue-storefront/core/helpers'
import ProductTile from 'theme/components/core/ProductTile'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import {
  fetchOmnibusPriceList,
  getProductsOnSaleSkus,
  proccessPrices
} from 'theme/helpers'

export default {
  name: 'ProductGalleryCarousel',
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    ProductTile
  },
  props: {
    list: {
      type: String,
      required: false,
      default: 'carousel'
    },
    products: {
      type: null,
      required: true
    },
    isMainPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      omnibusPriceMap: {},
      sliderConfig: {
        perPageCustom: [[576, 2], [992, 3], [1200, 4], [1400, 5]],
        centerMode: true,
        touchDrag: true,
        resistanceCoef: 400,
        paginationEnabled: true,
        paginationActiveColor: '#1396EC',
        paginationColor: '#CFCFCF',
        paginationPosition: 'bottom-overlay',
        navigationEnabled: true,
        navigationNextLabel: '&#10095;',
        navigationPrevLabel: '&#10094;'
      },
      currentPage: 0
    }
  },
  beforeMount () {
    this.updateOmnibusPriceMap()
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    productLink () {
      var t = {}
      for (var k = 0; k < this.products.length; k++) {
        t[k] = formatProductLink(this.products[k], currentStoreView().storeCode)
      }
      return t
    }
  },
  methods: {
    async updateOmnibusPriceMap () {
      if (!(this.products && this.products.length)) return
      const productsInSaleSkus = getProductsOnSaleSkus(this.products)
      if (!productsInSaleSkus || !productsInSaleSkus.length) return

      let omnibusPriceMap = await fetchOmnibusPriceList(productsInSaleSkus, this.currencyCode)
      if (!omnibusPriceMap) return
      omnibusPriceMap = proccessPrices(omnibusPriceMap)
      this.omnibusPriceMap = Object.assign({}, omnibusPriceMap, this.omnibusPriceMap)
    },
    handleSlideClick (index) {
      this.$router.push(this.productLink[index])
    },
    setMuted (currentPage) {
      if (this.isMainPage) {
        this.$emit('page-changed', { currentPage, perPage: this.$refs.carouselHomepage && this.$refs.carouselHomepage.perPage, type: this.list })
      }
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
.products-carousel {
  max-width: 100%;
  .VueCarousel-inner--center {
    justify-content: unset;
  }
  .VueCarousel-pagination--bottom-overlay {
    bottom: -25px !important;
  }
  .VueCarousel-navigation-prev {
    left: 20px !important;
  }
  .VueCarousel-navigation-next {
    right: 20px !important;
  }
  .VueCarousel-navigation-button {
    top: initial !important;
    bottom: -25px !important;
    background-color: #1396EC !important;
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    color: white !important;
    z-index: 2 !important;
  }
  .VueCarousel-dot {
    padding: 10px 8px !important;
  }
  .VueCarousel-navigation--disabled {
    display: none !important;
  }
  .VueCarousel-navigation-prev {
    transform: none !important;
  }
  .VueCarousel-navigation-next {
    transform: none !important;
  }
}
</style>

<style lang="scss" scoped>
.product {
  &.collection-product {
    padding: 0;
  }
}
</style>
