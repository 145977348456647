<template>
  <section
    class="new-collection bg-cl-main-smoke"
    v-if="product.related[type] && product.related[type].length > 0"
  >
    <div>
      <header class="col-md-12">
        <h2 class="my10 align-center cl-black secondary-font">
          <template v-if="isEmpty">
            {{ $t('Similar products') }}
          </template>
          <template v-else>
            {{ heading }}
          </template>
        </h2>
      </header>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <products-carousel :products="product.related[type]" :list="'related'" />
      </div>
    </div>
  </section>
</template>

<script>
import ProductsCarousel from 'theme/components/core/ProductsCarousel.vue'
// import ProductListing from 'theme/components/core/ProductListing'
import { mapGetters } from 'vuex'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import i18n from '@vue-storefront/i18n'
import config from 'config'
import bodybuilder from 'bodybuilder'

export default {
  name: 'Related',
  props: {
    type: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: i18n.t('Similar products')
    },
    categoryId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      isEmpty: false
    }
  },
  components: {
    // ProductListing,
    ProductsCarousel
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.refreshList)

    if (config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList)
      this.$bus.$on('user-after-logout', this.refreshList)
    }

    this.refreshList()
  },
  beforeDestroy () {
    if (config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList)
      this.$bus.$off('user-after-logout', this.refreshList)
    }
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.refreshList)
  },
  methods: {
    refreshList () {
      let sku = this.productLinks ? this.productLinks
        .filter(pl => pl.link_type === this.type)
        .map(pl => pl.linked_product_sku) : null
      let key = 'sku'

      if (sku === null || sku.length === 0) {
        if (this.type === 'related' && this.categoryId) {
          sku = [this.categoryId]
          key = 'category_ids'
        }
      }
      let relatedProductsQuery = prepareRelatedQuery(key, sku)

      this.$store.dispatch('product/findProducts', {
        query: relatedProductsQuery,
        size: 20,
        sort: 'view_count:desc',
        prefetchGroupProducts: false,
        includeFields: config.entities.productRelated.includeFields,
        excludeFields: config.entities.productRelated.excludeFields,
        updateState: false
      }).then((response) => {
        if (response) {
          let responseItems = response.items.filter(item => { return item.sku !== this.product.current.parentSku })
          if (responseItems.length > 4) {
            this.$store.dispatch('product/related', {
              key: this.type,
              items: responseItems
            })
            this.$forceUpdate()
          } else {
            let isBodyBuilderQuery = false
            let query
            if (this.type === 'related' && this.categoryId) {
              sku = [this.categoryId]
              key = 'category_ids'
            } else if (this.type === 'crosssel') {
              if (this.product.current.author) {
                query = bodybuilder()
                  .filter('match', 'author', this.product.current.author)
                  .filter('terms', 'visibility', [2, 3, 4])
                  .filter('terms', 'status', [1])
                  .filter('terms', 'stock.is_in_stock', [true])
                  .build()
                isBodyBuilderQuery = true
              } else if (this.product.current.magazine_id) {
                sku = [this.product.current.magazine_id]
                key = 'magazine_id'
              }
            }
            relatedProductsQuery = prepareRelatedQuery(key, sku)
            this.$store.dispatch('product/findProducts', {
              query: isBodyBuilderQuery ? query : relatedProductsQuery,
              size: 20 - responseItems.length,
              sort: 'view_count:desc',
              prefetchGroupProducts: false,
              updateState: false
            }).then((response) => {
              let oldSkus = responseItems.map(prod => prod.parentSku)
              let nextRuleItems = response.items.filter(item => item.sku !== this.product.current.parentSku && !oldSkus.includes(item.sku))
              if (nextRuleItems) {
                this.$store.dispatch('product/related', {
                  key: this.type,
                  items: [...responseItems, ...nextRuleItems]
                })
              }
              this.$forceUpdate()
            })
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      getProductRelated: 'product/getProductRelated'
    }),
    product () {
      return this.$store.state.product
    },
    productLinks () {
      return this.product.current.product_links
    }
  }
}
</script>

<style lang="scss" scoped>
.new-collection {
 padding: 15px;
 @media (max-width: 767px) {
  padding: 15px 10px;
 }
}
</style>
